/* eslint-disable import/first */
import { Suspense, lazy, useState, useEffect } from "react";
const LazyLandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));
// eslint-disable-next-line import/first
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import "./SASS/styles.scss";

import { Layout, Loader, NotFound } from "./MainComponents";
import {
  SignIn,
  Register,
  StudentLectures,
  StudentChapter,
  StudentLecture,
  Exams,
  SingleExamPage,
  HomePage,
  StudentQuestionBank,
  QuizNew,
  StudentShowQuestionBank,
  Profile,
  StudentSubjects,
  StudentSubjectTeachers,
  // Amount,
} from "./Pages/Student";
import {
  Posts,
  AddEditPost,
  AdminLayout,
  Students,
  EditStudent,
  Attendance,
  AdminLectures,
  AddEditChapter,
  AddEditLecture,
  Videos,
  AdminMaterials,
  AddEditMaterial,
  AddEditVideo,
  AdminChapter,
  AdminLecture,
  AdminQuiz,
  AdminExams,
  Codes,
  Requests,
  SessionStudents,
  SessionAbsence,
  Assistants,
  AddEditAssistant,
  QuestionBank,
  QuestionBankCategory,
  AddEditEssayQuestion,
  AddEditChooseQuestion,
  AddEditCategory,
  Whatsapp,
  ViewStudent,
  CodesTracker,
  AdminProfile,
  AdminAddEditExam,
  AdminViewExam,
  AdminAddStudentLecture,
  AdminCorrection,
  AdminStudentCorrection,
  AdminQuestionCorrection,
  AdminQuestionCorrectionForm,
  AttendanceAddSession,
  Reviews,
  Teachers,
  AddEditTeacher,
  AdminLecturesSubjects,
  AdminLecturesSubjectsTeachers,
} from "./Pages/Admin";

import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes";
import { useAuthState } from "./MainComponents/GlobalContext.jsx";
import ModalAnswer from "./Pages/Student/Lectures/modalAnswer/ModalAnswer";
import AdminModalAnswer from "./Pages/Admin/Dashboard/Exams/AdminModalAnswer.jsx";
import ForgetPassword from "./Pages/Student/SignIn/ForgetPassword.jsx";
import Analysis from "./Pages/Admin/Dashboard/Analysis/Analysis.jsx";
import TeacherAnalysis from "./Pages/Admin/Dashboard/Analysis/TeacherAnalysis.jsx";

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------
    {
      path: "/",
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/home"
          }`}
          isAllowed={!AuthState.userData}
        >
          <Suspense fallback={<Loader />}>
            <LazyLandingPage />
          </Suspense>
        </ProtectedAdminRoutes>
      ),
    },
    //!Student Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <Layout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/home/profile",
          element: <Profile />,
        },
        // {
        //   path: "/home/amount",
        //   element: <Amount />,
        // },

        {
          path: "/subjects",
          element: <StudentSubjects />,
        },
        {
          path: "/subjects/:subject",
          element: <StudentSubjectTeachers />,
        },
        {
          path: "/subjects/:subject/:teacherID",
          element: <StudentLectures />,
        },
        {
          path: "/lectures/chapters/:chapterID",
          element: <StudentChapter />,
        },
        {
          path: "/lectures/sessions/:lectureID",
          element: <StudentLecture />,
        },
        {
          path: "/lectures/sessions/modalAnswer/:lectureID",
          element: <ModalAnswer />,
        },
        {
          path: "/exams",
          element: <Exams />,
        },
        {
          path: "/exams/:examID",
          element: <SingleExamPage />,
        },
        {
          path: "/exams/solve-exam/:ID",
          element: <QuizNew exam />,
        },
        {
          path: "/exams/modal-answer/:ID",
          element: <ModalAnswer exam />,
        },
        //!Quiz
        {
          path: "/home/quiz/:ID",
          element: <QuizNew />,
        },
        //!Homework
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        //! Student Question Bank
        {
          path: "/home/question-bank",
          element: <StudentQuestionBank />,
        },
        {
          path: "/home/question-bank/:ID",
          element: <StudentShowQuestionBank />,
        },
      ],
    },
    //!Admin Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/admin/signin"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <AdminLayout />
        </ProtectedAdminRoutes>
      ),
      children: [
        //!--------- dashboard Routes --------------
        {
          path: "/admin/dashboard",
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions
              }
            >
              <Posts />
            </ProtectedAdminRoutes>
          ),
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("post_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/dashboard/edit-post/:postID",
              element: <AddEditPost edit />,
            },
          ],
        },
        //!------ Lectures Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects",
              element: <AdminLecturesSubjects />,
            },
            {
              path: "/admin/lectures-subjects/:subject",
              element: <AdminLecturesSubjectsTeachers />,
            },
            {
              path: "/admin/lectures-subjects/:subject/:teacherID",
              element: <AdminLectures admin />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("analysis")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/analysis",
              element: <Analysis />,
            },

            {
              path: "/admin/analysis/teacher/:teacherID",
              element: <TeacherAnalysis />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/:teacherID/add-chapter",
              element: <AddEditChapter />,
            },
            {
              path: "/admin/lectures-subjects/:teacherID/add-lecture",
              element: <AddEditLecture />,
            },
            {
              path: "/admin/lectures-subjects/:chapterID/:teacherID/add-lecture",
              element: <AddEditLecture />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/edit-chapter/:chapterID",
              element: <AddEditChapter edit />,
            },
            {
              path: "/admin/lectures-subjects/edit-lecture/:lectureID",
              element: <AddEditLecture edit />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/chapters/:chapterID/:teacherID",
              element: <AdminChapter />,
            },
            {
              path: "/admin/lectures-subjects/sessions/:lectureID",
              element: <AdminLecture admin />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addstudent")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/sessions/:lectureID/add-student",
              element: <AdminAddStudentLecture admin />,
            },
          ],
        },
        //!--- lecture_addvideos ---
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addvideos")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/sessions/:lectureID/videos",
              element: <Videos admin />,
            },
            {
              path: "/admin/lectures-subjects/:lectureID/add-video",
              element: <AddEditVideo admin />,
            },
            {
              path: "/admin/lectures-subjects/videos/edit-video/:VideoID",
              element: <AddEditVideo edit admin />,
            },
          ],
        },
        //!--- lecture_addmatrial ---
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addmatrial")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/sessions/:lectureID/materials",
              element: <AdminMaterials admin />,
            },
            {
              path: "/admin/lectures-subjects/:lectureID/add-material",
              element: <AddEditMaterial admin />,
            },
          ],
        },
        //!--- lecture_quiz ---

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addquiz")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/sessions/:lectureID/quiz",
              element: <AdminQuiz admin />,
            },
            {
              path: "/admin/lectures-subjects/sessions/quiz/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion admin edit lectureQuestion />,
            },
            {
              path: "/admin/lectures-subjects/sessions/quiz/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion admin edit lectureQuestion />,
            },
          ],
        },

        //!--- lecture_homework ---

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addHw")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures-subjects/sessions/:lectureID/homework",
              element: <AdminQuiz homework admin />,
            },
            {
              path: "/admin/lectures-subjects/sessions/homework/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion admin edit lectureQuestion />,
            },
            {
              path: "/admin/lectures-subjects/sessions/homework/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion admin edit lectureQuestion />,
            },
          ],
        },

        //!------ students Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students",
              element: <Students type="online" />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/add-student",
              element: <Register addNew />,
            },
            {
              path: "/admin/students/edit-student/:studentID",
              element: <EditStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/view-student/:studentID",
              element: <ViewStudent />,
            },
          ],
        },
        //!------ Attendance Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance",
              element: <Attendance />,
            },
            {
              path: "/admin/attendance/:lectureID/add-session",
              element: <AttendanceAddSession />,
            },
            {
              path: "/admin/attendance/:lectureID/edit-session/:sessionID",
              element: <AttendanceAddSession edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance/:groupID/:lectureID",
              element: <SessionStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance/:groupID/:lectureID/absence",
              element: <SessionAbsence />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams",
              element: <AdminExams />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/add-exam",
              element: <AdminAddEditExam />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/edit-exam/:ID",
              element: <AdminAddEditExam edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/all-students/:examID",
              element: <AdminViewExam />,
            },
            {
              path: "/admin/exams/all-students/:examID/model-answer",
              element: <AdminModalAnswer />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_addquestions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/:examID",
              element: <AdminQuiz exam />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes",
              element: <Codes />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_tracker")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes-tracker",
              element: <CodesTracker />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/requests",
              element: <Requests />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={true}
              // isAllowed={
              //   AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              //   AuthState.userData?.admin?.permissions?.some((permission) =>
              //     permission.name.startsWith("request_accept")
              //   )
              // }
            />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("user")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/teachers",
              element: <Teachers />,
            },
            {
              path: "/admin/teachers/add-teacher",
              element: <AddEditTeacher />,
            },
            {
              path: "/admin/teachers/edit-teacher/:teacherID",
              element: <AddEditTeacher edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("whatsapp_show")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/whatsapp",
              element: <Whatsapp />,
            },
          ],
        },
        //!---- reviews route ----
        {
          element: (
            <ProtectedAdminRoutes
               redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("reviews")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/reviews",
              element: <Reviews />,
            },
          ],
        },
      ],
    },
    //!Teacher Layout
    {
      element: (
        <ProtectedAdminRoutes
           redirectPath="/admin/signin"
          isAllowed={AuthState.userData?.teacher_token}
        >
          <AdminLayout teacher />
        </ProtectedAdminRoutes>
      ),
      children: [
        //!------ Lectures Routes --------------
        {
          path: "/teacher/lectures",
          element: <AdminLectures />,
        },
        {
          path: "/teacher/lectures/add-chapter",
          element: <AddEditChapter />,
        },
        {
          path: "/teacher/lectures/add-lecture",
          element: <AddEditLecture />,
        },
        {
          path: "/teacher/lectures/:chapterID/add-lecture",
          element: <AddEditLecture />,
        },

        {
          path: "/teacher/lectures/edit-chapter/:chapterID",
          element: <AddEditChapter edit />,
        },
        {
          path: "/teacher/lectures/edit-lecture/:lectureID",
          element: <AddEditLecture edit />,
        },

        {
          path: "/teacher/lectures/chapters/:chapterID",
          element: <AdminChapter />,
        },
        {
          path: "/teacher/lectures/sessions/:lectureID",
          element: <AdminLecture />,
        },

        {
          path: "/teacher/lectures/sessions/:lectureID/add-student",
          element: <AdminAddStudentLecture />,
        },
        {
          path: "/teacher/lectures/sessions/:lectureID/videos",
          element: <Videos />,
        },
        {
          path: "/teacher/lectures/:lectureID/add-video",
          element: <AddEditVideo />,
        },
        {
          path: "/teacher/videos/edit-video/:VideoID",
          element: <AddEditVideo edit />,
        },
        {
          path: "/teacher/lectures/sessions/:lectureID/materials",
          element: <AdminMaterials />,
        },
        {
          path: "/teacher/lectures/:lectureID/add-material",
          element: <AddEditMaterial />,
        },

        {
          path: "/teacher/lectures/sessions/:lectureID/quiz",
          element: <AdminQuiz />,
        },
        {
          path: "/teacher/lectures/sessions/quiz/edit-essay-question/:questionID",
          element: <AddEditEssayQuestion edit lectureQuestion />,
        },
        {
          path: "/teacher/lectures/sessions/quiz/edit-choose-question/:questionID",
          element: <AddEditChooseQuestion edit lectureQuestion />,
        },
        {
          path: "/teacher/lectures/sessions/:lectureID/homework",
          element: <AdminQuiz homework />,
        },
        {
          path: "/teacher/lectures/sessions/homework/edit-essay-question/:questionID",
          element: <AddEditEssayQuestion edit lectureQuestion />,
        },
        {
          path: "/teacher/lectures/sessions/homework/edit-choose-question/:questionID",
          element: <AddEditChooseQuestion edit lectureQuestion />,
        },

        // QUESTION BANK
        {
          path: "/teacher/question-bank",
          element: <QuestionBank />,
        },
        {
          path: "/teacher/question-bank/:categoryID",
          element: <QuestionBankCategory />,
        },
        {
          path: "/teacher/question-bank/add-category",
          element: <AddEditCategory />,
        },
        {
          path: "/teacher/question-bank/edit-category/:categoryID",
          element: <AddEditCategory edit />,
        },
        {
          path: "/teacher/question-bank/:categoryID/add-essay-question",
          element: <AddEditEssayQuestion />,
        },
        {
          path: "/teacher/question-bank/:categoryID/add-choose-question",
          element: <AddEditChooseQuestion />,
        },
        {
          path: "/teacher/question-bank/:categoryID/edit-essay-question/:questionID",
          element: <AddEditEssayQuestion edit />,
        },
        {
          path: "/teacher/question-bank/:categoryID/edit-choose-question/:questionID",
          element: <AddEditChooseQuestion edit />,
        },

        {
          path: "/teacher/profile",
          element: <AdminProfile teacher />,
        },
      ],
    },

    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/lectures"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn

        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },
        {
          path: "/teacher/signin",
          element: <SignIn teacher />,
        },
        //!Register
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },

    //!NotFound
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <main className="dar:text-light text-secondary duration-100 dark:bg-dark">
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </main>
  );
}

export default App;
