import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { DatePicker, Spin } from "antd";
import Loader from "../../../../MainComponents/Loader";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";

const { RangePicker } = DatePicker;
export default function TeacherAnalysis() {
  const { teacherID } = useParams();
  const [date, setDate] = useState([]);
  const [stage1, setStage1] = useState(null);
  const [stage2, setStage2] = useState(null);
  const [stage3, setStage3] = useState(null);
  const [stage4, setStage4] = useState(null);
  const [submitTeacher,setSubmitTeacher] = useState({
    flag:"",
    depedency:false,
    data:{}
  });


  const [TeacherssData, TeacherssErrors, allTeachersLoading] = useAxios(
    process.env.REACT_APP_ADMIN_STATISTICS_TEACHER_VIEW_POST_API,
    "POST",
    submitTeacher.flag,
    submitTeacher.depedency,
    submitTeacher.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  // Handle the date change
  const handleChangeDate = (dates) => {
    if (dates[0] && dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];
      setDate([startDate, endDate]);
    }
  };

  const onSubmit = (data) => {
    const [from, to] = date;
    setSubmitTeacher({
      flag: "Change Table",
      depdency: !submitTeacher.depedency,
      data: {
        from,
        to,
        teacher_id:teacherID
      },
    });
  };

  const labels = ["Third Prep.", "First Sec.", "Second Sec.", "Third Sec."];

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Third Prep.",
        data:  [stage1], 
        backgroundColor: "#4285f4",
        borderColor: "#4285f4",
        pointBorderColor: "#4285f4",
        tension: 0.4,
      },
      {
        label: "First Sec.",
        data:  [stage2],
        backgroundColor: "rgb(110, 231, 183)",
        borderColor: "rgb(110, 231, 183)",
        pointBorderColor: "rgb(110, 231, 183)",
        tension: 0.4,
      },
      {
        label: "Second Sec.",
        data:  [stage3],
        backgroundColor: "rgba(189, 19, 236)",
        borderColor: "rgba(189, 19, 236)",
        pointBorderColor: "rgba(189, 19, 236)",
        tension: 0.4,
      },
      {
        label: "Third Sec.",
        data:[stage4],
        backgroundColor: "rgba(200, 19, 236)",
        borderColor: "rgba(200, 19, 236)",
        pointBorderColor: "rgba(200, 19, 236)",
        tension: 0.4,
      },
    ],
  };

  useEffect(() => {
    if (TeacherssData?.data?.buying_history_per_stage) {
      const buyingHistory = TeacherssData.data.buying_history_per_stage;
  
      setStage1(buyingHistory["33"] || 0); // Handle missing keys with a default value
      setStage2(buyingHistory["1"] || 0);
      setStage3(buyingHistory["2"] || 0);
      setStage4(buyingHistory["3"] || 0);
    }
  }, [TeacherssData]);
  return (
    <section className=" w-full px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Teacher Analysis | Prodigy" />
           <div className="px-10 py-10 flex flex-col gap-5">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-1/2 sm:w-full space-y-5 rounded-3xl shadow-3xl p-4"
      >
        <h5 className="text-3xl font-bold">Get Buying History Teacher</h5>
        {/* Date Range Picker */}
        <RangePicker
          className="signin-inputs"
          onChange={handleChangeDate}
          getPopupContainer={(trigger) => trigger.parentNode}
        />



      {/* Submit Button */}
      <button type="submit" className="submit" disabled={allTeachersLoading || !isValid}>
          {allTeachersLoading ?  <Spin  /> : "Submit" }
        </button>
      </form>
    </div>
      <h2 className="w-full text-4xl font-bold md:text-center">
        The top best-selling class
      </h2>
      <div className="w-full h-96">
        <Bar data={chartData} />
      </div>
    </section>
  );
}
