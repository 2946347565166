import { Link } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Bar, Line } from "react-chartjs-2";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { DatePicker, Spin } from "antd";
import Loader from "../../../../MainComponents/Loader";

const { RangePicker } = DatePicker;




export default function Analysis() {

  const [date, setDate] = useState([]);
  const [submitTeacher,setSubmitTeacher] = useState({
    flag:"",
    depedency:false,
    data:{}
  });


  const [allTeacherssData, allTeacherssErrors, allTeachersLoading] = useAxios(
    process.env.REACT_APP_ADMIN_STATISTICS_TEACHERS_POST_API,
    "POST",
    submitTeacher.flag,
    submitTeacher.depedency,
    submitTeacher.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  // Handle the date change
  const handleChangeDate = (dates) => {
    if (dates[0] && dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];
      setDate([startDate, endDate]);
    }
  };

  const onSubmit = (data) => {
    const [from, to] = date;
    setSubmitTeacher({
      flag: "Change Table",
      depdency: !submitTeacher.depedency,
      data: {
        from,
        to,
      },
    });
  };
  

  const barChartData = {
    labels: ["Third Prep.", "First Sec.", "Second Sec.", "Third Sec."],
    datasets: [
      {
        data: [30, 50, 100, 30],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)", // Light Red
          "rgba(54, 162, 235, 0.5)", // Light Blue
          "rgba(255, 206, 86, 0.5)", // Light Yellow
          "rgba(75, 192, 192, 0.5)", // Light Teal
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)", // Red
          "rgba(54, 162, 235, 1)", // Blue
          "rgba(255, 206, 86, 1)", // Yellow
          "rgba(75, 192, 192, 1)", // Teal
        ],
        borderWidth: 2,
        barThickness: 20, // Set a fixed bar thickness
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable the legend completely
      },
      title: {
        display: false,
        text: "Top Teachers Analysis",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Performance Score",
        },
      },
      x: {
        title: {
          display: true,
          text: "Grade Levels",
        },
      },
    },
  };
  // Line Chart Data
  const lineChartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Teacher A",
        data: [30, 45, 60, 70, 80, 75, 85, 90, 100, 95, 110, 120],
        borderColor: "rgba(75, 192, 192, 1)", // Teal
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Light Teal
        tension: 0.4, // Smooth curve
      },
      {
        label: "Teacher B",
        data: [20, 30, 50, 65, 70, 60, 75, 80, 90, 85, 100, 110],
        borderColor: "rgba(255, 99, 132, 1)", // Red
        backgroundColor: "rgba(255, 99, 132, 0.2)", // Light Red
        tension: 0.4,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Performance Analysis",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Performance Score ",
        },
      },
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
    },
  };

  return (
    <section className="h-auto w-full overflow-y-auto space-y-10 px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Analysis | PRODIGY"></HelmetTags>
      <div className="px-10 py-10 flex flex-col gap-5">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-1/2 sm:w-full space-y-5 rounded-3xl shadow-3xl p-4"
      >
        <h5 className="text-3xl font-bold">Get Buying History Teacher</h5>
        {/* Date Range Picker */}
        <RangePicker
          className="signin-inputs"
          onChange={handleChangeDate}
          getPopupContainer={(trigger) => trigger.parentNode}
        />



      {/* Submit Button */}
      <button type="submit" className="submit" disabled={allTeachersLoading || !isValid}>
          {allTeachersLoading ?  <Spin  /> : "Submit" }
        </button>
      </form>
    </div>
      <div className="flex flex-wrap gap-10 justify-center w-full">
        {allTeachersLoading?
        <Loader/>:
        allTeacherssData && 
        
        allTeacherssData?.data?.map((teacher, index) => (
          <div className="max-w-[344px] w-full" key={index}>
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <Link className="profile_pic h-60 w-60 overflow-hidden rounded-full">
                <img
                  className="h-full w-full rounded-full object-cover object-top"
                  src={`https://api.prodigy-online.com/uploads/profile_picture/${teacher.profile_pic}`}
                  alt={teacher.name}
                />
              </Link>
              <Link
                to={`/admin/analysis/teacher/${teacher.id}`}
                className="font-bold text-4xl hover:text-primary"
              >
                {teacher?.name}
              </Link>
              <p className="font-bold">Buy History: {teacher?.buying_history}</p>
              {/*        <p>{teacher?.description}</p> */}
            </div>
          </div>
        ))}
      </div>
      <div className=" w-full">
        <h2 className=" font-bold text-5xl mb-5">Top Ten Teachers </h2>
        <Bar data={barChartData} options={chartOptions} />
      </div>
      {/* Line Chart */}
      <div className=" w-full">
        <h2 className="font-bold text-5xl mb-5">
          Monthly Performance Analysis
        </h2>
        <Line data={lineChartData} options={lineChartOptions} />
      </div>
    </section>
  );
}
