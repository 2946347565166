import React from "react";

import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Col, Row } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";

function AdminLecturesSubjectsTeachers() {
  const AuthState = useAuthState();
  const { subject } = useParams();

  const [allTeacherssData, allTeacherssErrors, allTeachersLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURES_GET_TEACHER_API}/${subject}`,
    "GET",
    "GET"
  );

  if (allTeachersLoading) return <Loader />;

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Teachers | PRODIGY"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Teachers</h2>
      </div>
      <Row gutter={[24, 24]} align="middle" justify="center">
        {allTeacherssData?.data?.map((teacher, index) => (
          <Col xs={24} md={12} lg={8} key={index}>
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <div className="profile_pic h-60 w-60 overflow-hidden rounded-full">
                <img
                  className="h-full w-full rounded-full object-cover object-top"
                  src={teacher.profile_pic}
                  alt={teacher.name}
                />
              </div>

              <h3 className="font-bold text-4xl">
                <Link
                  to={`/admin/lectures-subjects/${subject}/${teacher?.key}`}
                >
                  {teacher?.name}
                </Link>
              </h3>
              <p className="font-bold">{teacher?.subject}</p>
              <p>{teacher?.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default AdminLecturesSubjectsTeachers;
